import React from "react";
import { Row, Col, Card, Image, Form, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";
import "../EzokAdvantage/EzokAdvantage.js";

function EzokAdvantage() {
  return (
    <>
      <section>
        <div
          style={{
            backgroundImage: `url("images/advantage/black.jpg")`,
            width: "100%",
            left: "0",
          }}
        >
          <div className="py-2">
            <Image src="images/dividers/advantage.jpg" fluid />
            <Row className="py-3" style={{ width: "65%", margin: "auto" }}>
              <Col md={4}>
                <Card>
                  <Card.Img variant="top" src="images/advantage/a3.jpg" />
                  <Card.Body
                    style={{ backgroundColor: "black", fontFamily: "serif" }}
                  >
                    <Card.Title style={{ color: "#fff", fontSize: "1.4rem" }}>
                      Card Title
                    </Card.Title>
                    <Card.Text style={{ color: "#fff", fontSize: "0.9rem" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <Card.Img variant="top" src="images/advantage/a4.jpg" />
                  <Card.Body
                    style={{ backgroundColor: "black", fontFamily: "serif" }}
                  >
                    <Card.Title style={{ color: "#fff", fontSize: "1.4rem" }}>
                      Card Title
                    </Card.Title>
                    <Card.Text style={{ color: "#fff", fontSize: "0.9rem" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
              <Col md={4}>
                <Card>
                  <Card.Img variant="top" src="images/advantage/a2.jpg" />
                  <Card.Body
                    style={{ backgroundColor: "black", fontFamily: "serif" }}
                  >
                    <Card.Title style={{ color: "#fff", fontSize: "1.4rem" }}>
                      Card Title
                    </Card.Title>
                    <Card.Text style={{ color: "#fff", fontSize: "0.9rem" }}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt ut labore et dolore magna
                      aliqua. Ut enim ad minim veniam
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
            <Row className="py-2">
              <Col>
                <Row className="ps-2">
                  <Col>
                    <p style={{ fontFamily: "sans-serif", color: "white" }}>
                      ₹200 Coupon For First Shopping
                    </p>
                  </Col>
                </Row>
                <Row className="ps-2">
                  <Col>
                    <h2 style={{ color: "white" }}>Get The Latest Deals</h2>
                  </Col>
                </Row>
              </Col>
              <Col className="m-auto">
                <Form
                  className="d-flex"
                  style={{ justifyContent: "center", width: "80%" }}
                >
                  <FormControl
                    type="search"
                    style={{
                      background: "black",
                      borderRadius: "0px",
                      borderRight: "none",
                    }}
                    className="mr-2 input3 customInput2"
                    aria-label="Search"
                  ></FormControl>
                  <Link
                    className="btn btn-outline-light input2Button"
                    to="/search"
                    style={{
                      borderLeft: "none",
                      borderRadius: "0px",
                      color: "#d2ac5f",
                    }}
                  >
                    <strong>SUBSCRIBE</strong>
                  </Link>
                </Form>
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </>
  );
}

export default EzokAdvantage;
