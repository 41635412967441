import React, { useContext, useEffect } from "react";
import { Image, Container, Row } from "react-bootstrap";
import { ShopContext } from "../context/shopContext";
import CarousalComponent from "../components/Carousal/CarousalComponent";
import CarousalComponent2 from "../components/Carousal2/CarousalComponent2";
import Jumbotrons from "../components/Jumbotrons/Jumbotrons";
import Categories from "../components/Categories/Categories";
import ProductParent from "../components/ProductParent/ProductParent";
import ShopByInstagram from "../components/ShopByInstagram/ShopByInstagram";
import EzokAdvantage from "../components/EzokAdvantage/EzokAdvantage";
import Blogs from "../components/Blogs/Blogs";
import FooterTop from "../components/FooterTop/FooterTop";

function Home() {
  const { fetchAllProducts, products } = useContext(ShopContext);

  useEffect(() => {
    fetchAllProducts();
  }, []);

  return (
    <>
      <CarousalComponent />
      <Container>
        <Jumbotrons />
        {/* <Categories /> */}
        <ProductParent products={products} />
      </Container>

      <Container>
        <Row>
          <Image src="images/dividers/follow.jpg" fluid />
        </Row>
        <ShopByInstagram />
        <CarousalComponent2 />
        <Blogs />
      </Container>
      <div style={{ backgroundColor: "black" }}>
        <Container>
          <EzokAdvantage />
        </Container>
      </div>
      {/* <FooterTop /> */}
      <Image src="images/footer/footer.jpg" fluid></Image>
    </>
  );
}

export default Home;
