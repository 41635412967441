import React, { useState } from "react";
import { Image, Row, Col } from "react-bootstrap";
import "../Carousal2/Carousal2.css";

function CarousalComponent2() {
  return (
    <section className="py-5">
      <Row className="my-5">
        <Col>
          <Image src="images/banners/model.jpg" fluid />
        </Col>
      </Row>
    </section>
  );
}

export default CarousalComponent2;
