import React, { useEffect, useState } from "react";
import Client from "shopify-buy";

const ShopContext = React.createContext();

const client = Client.buildClient({
  storefrontAccessToken: "e730d51e306817324f8fccf45ecfb05b",
  domain: "ezok-react.myshopify.com",
});

function ShopProvider({ children }) {
  const [products, setProducts] = useState([]);
  const [product, setProduct] = useState({});
  const [checkout, setCheckout] = useState({});
  const [isCartOpen, setIsCartopen] = useState(false);

  useEffect(() => {
    createCheckout();
  }, []);

  const createCheckout = async () => {
    const checkoutData = await client.checkout.create();
    setCheckout(checkoutData);
  };

  const addItemToCheckout = async (variantId, quantity) => {
    const lineItemsToAdd = [
      {
        variantId,
        quantity: parseInt(quantity, 10),
      },
    ];

    const checkoutData = await client.checkout.addLineItems(
      checkout.id,
      lineItemsToAdd
    );

    setCheckout(checkoutData);
  };

  const fetchAllProducts = async () => {
    const productsData = await client.product.fetchAll();
    setProducts(productsData);
  };

  const fetchProductWithId = async (id) => {
    const singleProductData = await client.product.fetch(id);
    setProduct(singleProductData);
  };

  const closeCart = () => {
    setIsCartopen(false);
  };
  const openCart = () => {
    setIsCartopen(true);
  };

  return (
    <ShopContext.Provider
      value={{
        products,
        product,
        checkout,
        isCartOpen,
        fetchAllProducts,
        fetchProductWithId,
        closeCart,
        openCart,
        addItemToCheckout,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
}

const ShopConsumer = ShopContext.Consumer;

export { ShopConsumer, ShopContext };

export default ShopProvider;
