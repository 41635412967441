import React from "react";
import { Row, Col, Image } from "react-bootstrap";

function Jumbotrons() {
  return (
    <>
      <Row className="mx-auto px-5 pt-5">
        <Col>
          <Row>
            <Col md={3}>
              <Image src="images/icons/j1.jpg" fluid></Image>
            </Col>
            <Col
              md={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h5 style={{ textTransform: "uppercase", fontWeight: "400" }}>
                Premium Leather
              </h5>
              <p>Hand Picked Forquality</p>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md={3}>
              <Image src="images/icons/j2.jpg" fluid></Image>
            </Col>
            <Col
              md={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h5 style={{ textTransform: "uppercase", fontWeight: "400" }}>
                Handmade By Experts
              </h5>
              <p>Finest Quality Shoemakers</p>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row>
            <Col md={3}>
              <Image src="images/icons/j3.jpg" fluid></Image>
            </Col>
            <Col
              md={9}
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
              }}
            >
              <h5 style={{ textTransform: "uppercase", fontWeight: "400" }}>
                Sustainable crafted
              </h5>
              <p>Good For you and the planet</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
}

export default Jumbotrons;
