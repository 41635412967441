import React, { useEffect, useState } from "react";
import { Row, Col, Image } from "react-bootstrap";
import FilterProducts from "../FilterProducts/FilterProducts";
import "../ProductParent/ProductParent.css";

function ProductParent({ products }) {
  const [filterProducts, setFilterProducts] = useState([]);
  const [active, setActive] = useState(false);

  useEffect(() => {
    setFilterProducts(products);
  }, []);

  const handleChange = (f, l) => {
    setFilterProducts(products.slice(f, l));
  };

  const handleReset = () => {
    setFilterProducts(products.slice(10, 20));
  };
  return (
    <>
      <Row className="pt-5">
        <Col className="overHidden">
          <Image
            src="images/categories/c1.jpg"
            className="hoverchild"
            fluid
            onMouseEnter={(e) => handleChange(10, 15)}
            onMouseLeave={!active && handleReset}
            onClick={(e) => setActive((prev) => !prev)}
          />
        </Col>
        <Col className="overHidden">
          <Image
            src="images/categories/c2.jpg"
            className="hoverchild"
            fluid
            onMouseEnter={(e) => handleChange(15, 20)}
            onMouseLeave={!active && handleReset}
            onClick={(e) => setActive((prev) => !prev)}
          />
        </Col>
        <Col className="overHidden">
          <Image
            src="images/categories/c3.jpg"
            className="hoverchild"
            fluid
            onMouseEnter={(e) => handleChange(0, 5)}
            onMouseLeave={!active && handleReset}
            onClick={(e) => setActive((prev) => !prev)}
          />
        </Col>
        <Col className="overHidden">
          <Image
            src="images/categories/c4.jpg"
            className="hoverchild"
            fluid
            onMouseEnter={(e) => handleChange(5, 10)}
            onMouseLeave={!active && handleReset}
            onClick={(e) => setActive((prev) => !prev)}
          />
        </Col>
      </Row>
      <FilterProducts filterProducts={filterProducts} />
    </>
  );
}

export default ProductParent;
