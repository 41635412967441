import React, { useContext } from "react";
import {
  Nav,
  Navbar,
  Container,
  Image,
  Form,
  FormControl,
} from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { ShopContext } from "../../context/shopContext";
import "../Header/Header.css";
import { Bag, Heart, Person, Search } from "react-bootstrap-icons";
import { Link } from "react-router-dom";

function Header() {
  const { openCart } = useContext(ShopContext);

  return (
    <header>
      <Navbar className="nav-bg" expand="lg" collapseOnSelect>
        <Container>
          <LinkContainer to="/">
            <Navbar.Brand>
              <Image
                src="images/logo/logoF.png"
                alt="img"
                fluid
                width="120px"
              />
            </Navbar.Brand>
          </LinkContainer>
          <Form className="d-flex w-50 me-auto">
            <Link className="customInputButton" to="/search">
              <Search />
            </Link>
            <FormControl
              type="search"
              placeholder="Find Your Style"
              className="mr-2 customInput"
              aria-label="Search"
            ></FormControl>
          </Form>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <LinkContainer to="/products">
                <Nav.Link>
                  <Person />
                </Nav.Link>
              </LinkContainer>

              <Nav.Link>
                <Heart />
              </Nav.Link>

              <Nav.Link>
                <Bag onClick={() => openCart()} />
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
}

export default Header;
