import React from "react";
import "../ShopByInstagram/ShopByInstagram.css";
import { Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";

function ShopByInstagram() {
  return (
    <section>
      <div
        style={{
          backgroundImage: `url("images/banners/yellow.jpg")`,

          width: "100%",
          height: "500px",
          position: "absolute",
          left: "0",
          marginTop: "50px",
          zIndex: "-1",
        }}
      ></div>
      <Row className="py-2" style={{ width: "80%" }}>
        <Col>
          <Image className="hoverE" src="images/instagram/1.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/2.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/5.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/6.jpg" fluid></Image>
        </Col>
      </Row>
      <Row className="py-2" style={{ width: "80%" }}>
        <Col>
          <Image className="hoverE" src="images/instagram/6.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/7.jpg" fluid></Image>
        </Col>
        <Col>
          <Image className="hoverE" src="images/instagram/8.jpg" fluid></Image>
        </Col>
        <Col>
          <Image
            className="hoverE"
            src="https://cdn.shopify.com/s/files/1/0570/6370/2695/articles/1_1024x1024.jpg?v=1627709280"
            fluid
          ></Image>
        </Col>
      </Row>
      <Row className="px-5" style={{ textAlign: "right" }}>
        <Link
          style={{
            fontFamily: "sans-serif",
            textDecoration: "none",
            color: "black",
          }}
        >
          EXPLORE MORE&nbsp;
          <ArrowRight />
        </Link>
      </Row>
    </section>
  );
}

export default ShopByInstagram;
