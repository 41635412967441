import React from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
import Header from "./components/Header/Header";
import HeaderSideNav from "./components/HeaderSideNav/HeaderSideNav";
import clsx from "clsx";
import ShopProvider from "./context/shopContext";
import Home from "./pages/Home";
import ProductsDetails from "./pages/ProductsDetails";
import SidebarSocial from "./components/SidebarSocial/SidebarSocial";
// import Cart from "../src/components/Cart/Cart";

const drawerWidth = 240;
const SocialDrawerWidth = 60;

function App() {
  return (
    <ShopProvider>
      <Router>
        <HeaderSideNav />
        <SidebarSocial />
        {/* <Header /> */}
        {/* <Cart /> */}
        <main
          style={{
            width: `calc(100% - ${drawerWidth + SocialDrawerWidth}px)`,
            marginLeft: drawerWidth,
            marginRight: SocialDrawerWidth,
            // paddingTop: "64px",
          }}
        >
          <Route path="/product/:id" component={ProductsDetails} exact></Route>
          <Route path="/" component={Home} exact></Route>
        </main>
      </Router>
    </ShopProvider>
  );
}

export default App;
