import React, { useState } from "react";
import { Row, Col, Carousel } from "react-bootstrap";
import "../Carousal/Carousal.css";
import { fadeInDownBig } from "react-animations";
import Radium, { StyleRoot } from "radium";

function CarousalComponent() {
  const styles = {
    fadeInDownBig: {
      animation: "x 1s",
      animationName: Radium.keyframes(fadeInDownBig, "fadeInDownBig"),
    },
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "65px 80px 0 80px",
        }}
      >
        <Carousel
          controls={false}
          indicators={false}
          touch={false}
          pause={false}
        >
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/banner21.jpg"
              alt="First slide"
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/banner31.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100"
              src="images/banners/banner41.jpg"
              alt="First slide"
            />
          </Carousel.Item>
        </Carousel>
        <Carousel
          controls={false}
          indicators={false}
          touch={false}
          pause={false}
        >
          <Carousel.Item>
            <img
              className="d-block w-100 sHover"
              src="images/banners/banner22.jpg"
              alt="First slide"
            />
          </Carousel.Item>

          <Carousel.Item>
            <img
              className="d-block w-100 sHover"
              src="images/banners/banner32.jpg"
              alt="First slide"
            />
          </Carousel.Item>
          <Carousel.Item>
            <img
              className="d-block w-100 sHover"
              src="images/banners/banner42.jpg"
              alt="First slide"
            />
          </Carousel.Item>
        </Carousel>
        {/* <Row>
        <Col>
          <img
            className="d-block w-100"
            src="images/banners/b211.jpg"
            alt="First slide"
            style={styles.fadeInDownBig}
          />
        </Col>
        <Col>
          <img
            className="d-block w-100 sHover"
            src="images/banners/b212.jpg"
            alt="First slide"
            style={styles.fadeInDownBig}
          />
        </Col>
      </Row> */}
      </div>
    </>
  );
}

export default CarousalComponent;
