import React from "react";
import { Row, Col, Image } from "react-bootstrap";

function Categories() {
  return (
    <section className="mt-5">
      <Row>
        <Col className="p-0">
          <Image src="images/banners/banner_4v3.jpg" fluid />
        </Col>
      </Row>
    </section>
  );
}

export default Categories;
