import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Drawer from "@material-ui/core/Drawer";
import CssBaseline from "@material-ui/core/CssBaseline";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import List from "@material-ui/core/List";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import ListItem from "@material-ui/core/ListItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import MailIcon from "@material-ui/icons/Mail";
import "../HeaderSideNav/HeaderSideNav.css";
import { ArrowRight, Bag, Heart, Person, Search } from "react-bootstrap-icons";
import { Form, FormControl } from "react-bootstrap";
import { Link } from "react-router-dom";

const drawerWidth = 240;
const SocialDrawerWidth = 60;

const useStyles = makeStyles((theme) => ({
  root: {
    // display: "flex",
  },
  appBar: {
    width: `calc(100% - ${drawerWidth + SocialDrawerWidth}px)`,
    marginLeft: drawerWidth,
    marginRight: SocialDrawerWidth,
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    // borderRight: "0",
    float: "left",
    position: "fixed",
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  listItemText: {
    fontSize: "0.9rem",
  },
}));

export default function PermanentDrawerLeft() {
  const [offSet, setOffSet] = useState();

  useEffect(() => {
    document.addEventListener("scroll", () => {
      setOffSet(window.scrollY);
    });
  }, []);

  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CssBaseline />
      {/* <Form
        className="d-flex me-auto"
        style={{ position: "absolute", left: "0", top: "25px", width: "280px" }}
      >
        <Link className="customInputButton" to="/search">
          <Search />
        </Link>
        <FormControl
          type="search"
          placeholder="Find Your Style"
          className="mr-2 customInput"
          aria-label="Search"
        ></FormControl>
      </Form> */}
      <AppBar
        // position={offSet < 2000 ? "fixed" : "relative"}
        position="fixed"
        className={classes.appBar}
        style={{ background: "white", color: "black" }}
      >
        <Toolbar>
          <Link style={{ color: "black" }}>
            <div id="wrap">
              <form action="">
                <input
                  id="search"
                  name="search"
                  type="text"
                  placeholder="What're you looking for ?"
                  autoComplete="off"
                />
                <input id="search_submit" value="Rechercher" type="submit" />
              </form>
            </div>
          </Link>
          <Typography variant="h6" noWrap>
            <Link style={{ color: "black" }}>
              <Person />
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link style={{ color: "black" }}>
              <Heart />
            </Link>
            &nbsp;&nbsp;&nbsp;
            <Link style={{ color: "black" }}>
              <Bag />
            </Link>
            &nbsp;&nbsp;&nbsp;
          </Typography>
        </Toolbar>
      </AppBar>

      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor="left"
      >
        <div className={classes.toolbar} />
        <div className="text-center pb-5">
          <img
            src="images/logo/logoF.png"
            alt="logo"
            style={{ width: "60%" }}
          ></img>
        </div>
        <Divider />
        <List>
          <ListItem button>
            <ListItemText
              primary="HOME"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>

          <ListItem button>
            <ListItemText
              primary="FORMALS"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>

          <ListItem button>
            <ListItemText
              primary="CASUALS"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>

          <ListItem button>
            <ListItemText
              primary="PARTY"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>

          <ListItem button>
            <ListItemText
              primary="COMFORT"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>

          <ListItem button>
            <ListItemText
              primary="WHATS TRENDING ?"
              classes={{ primary: classes.listItemText }}
            />
          </ListItem>
        </List>
      </Drawer>
    </div>
  );
}
