import React, { useEffect, useContext } from "react";
import { Col, Row, Image, Button } from "react-bootstrap";
import { ShopContext } from "../context/shopContext";

function ProductsDetails({ match }) {
  let productId = match.params.id;

  const { fetchProductWithId, addItemToCheckout, product, openCart } =
    useContext(ShopContext);

  useEffect(() => {
    fetchProductWithId(productId);
    return () => {};
  }, [productId]);

  console.log(product);

  return (
    <>
      {!product.title ? (
        "loading"
      ) : (
        <Row>
          <Col>
            <Image src={product.images[0].src} fluid />
          </Col>
          <Col>
            <p>{product.title}</p>
            <p>{product.variants[0].price}</p>
            <Button
              onClick={() => {
                addItemToCheckout(product.variants[0].id, 1);
                openCart();
              }}
            >
              Add to cart
            </Button>
          </Col>
        </Row>
      )}
    </>
  );
}

export default ProductsDetails;
