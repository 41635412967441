import React, { useEffect } from "react";
import { Row, Col, Container, Button, Image } from "react-bootstrap";
import Product from "../Product/Product";
import { Link } from "react-router-dom";
import { ArrowRight } from "react-bootstrap-icons";
import { Divider } from "@material-ui/core";

function FilterShoes({ filterProducts }) {
  return (
    <section>
      <Row className="pt-4">
        <Col>
          {!filterProducts ? (
            "Loading"
          ) : (
            <Row md={5}>
              {filterProducts.map((product) => {
                return (
                  <Col key={product.id}>
                    <Product product={product} />
                  </Col>
                );
              })}
            </Row>
          )}
        </Col>
      </Row>
      <Row className="mt-5 mb-5">
        <Col style={{ textAlign: "right" }}>
          <Link to="/" style={{ color: "black", textDecoration: "none" }}>
            EXPLORE MORE <ArrowRight />
          </Link>
        </Col>
        <Divider variant="inset" component="row" className="mt-4" />
      </Row>
    </section>
  );
}

export default FilterShoes;
